* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*
* Template Name: UntreeStore
* Template Author: Untree.co
* Author URI: https://untree.co/
* License: https://creativecommons.org/licenses/by/3.0/
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  overflow-x: hidden;
  position: relative;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 28px;
  color: #6a6a6a;
  font-size: 14px;
  background-color: #eff2f1;
}

a {
  text-decoration: none;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  color: #2f2f2f;
}
a:hover {
  color: #2f2f2f;
  text-decoration: none;
}
a.more {
  font-weight: 600;
}

.custom-navbar {
  background: #d8cbba !important;
  position: fixed;
  width: 100%;
  z-index: 100;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 578px) {
  .hero .hero-img-wrap img,
  .hero .hero-img-wrap:after {
    width: 100%;
  }
  .top-g {
    padding-top: 79px !important;
  }
  .custom-navbar {
    width: 100% !important;
    /* position: absolute; */
  }
  .product-section {
    padding-top: 30px !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
  }
  .why-choose-section {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .product-section .product-item {
    padding-bottom: 10px !important;
  }
  .section-title {
    font-size: 32px !important;
  }
  .bg-img {
    display: none;
  }
  .custom-list li {
    margin-left: 0px !important;
    font-size: 13px;
  }
  .we-help-section {
    padding-bottom: 20px !important;
    margin-top: 20px !important;
  }
  .popular-product {
    padding-bottom: 20px !important;
  }
  .txt-1-a {
    padding-top: 30px;
  }
  .footer-g {
    align-items: start !important;
  }
  .product-gap {
    padding-top: 100px !important;
  }
  .product-item::after {
    width: 100% !important;
    height: auto !important;
    border-radius: 20px !important;
  }
  .txt-1 {
    font-size: 28px !important;
  }
  .txt-p {
    font-size: 13px !important;
  }
}

@media screen and (min-width: 579px) and (max-width: 880px) {
  .hero .hero-img-wrap img,
  .hero .hero-img-wrap:after {
    width: 100%;
  }
  .top-g {
    padding-top: 79px !important;
  }
  .custom-navbar {
    width: 100% !important;
    /* position: absolute; */
  }
  .product-section {
    padding-top: 30px !important;
    padding-bottom: 10px !important;
  }
  .why-choose-section {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .product-section .product-item {
    padding-bottom: 10px !important;
  }
  .section-title {
    font-size: 32px !important;
  }
  .bg-img {
    display: none;
  }
  .custom-list li {
    margin-left: 0px !important;
    font-size: 13px;
  }
  .we-help-section {
    padding-bottom: 20px !important;
  }
  .popular-product {
    padding-bottom: 20px !important;
  }
  .txt-1-a {
    padding-top: 30px;
  }
  .footer-g {
    align-items: start !important;
  }
  .product-gap {
    padding-top: 100px !important;
  }
  .product-item::after {
    width: 100% !important;
    height: auto !important;
    border-radius: 20px !important;
  }
  .txt-1 {
    font-size: 28px !important;
  }
  .txt-p {
    font-size: 13px !important;
  }
}

.custom-navbar .navbar-brand {
  font-size: 32px;
  font-weight: 600;
  color: #394c6c;
}

.bg-color {
  background-color: #e7ded2;
}

.txt-1 {
  color: #394c6c;
  /* text-align: center; */
  font-size: 35px;
  font-weight: 700;
}

.txt-p {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}

.txt-p > span {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.custom-navbar .navbar-brand > span {
  opacity: 0.4;
}
.custom-navbar .navbar-toggler {
  border-color: transparent;
}
.custom-navbar .navbar-toggler:active,
.custom-navbar .navbar-toggler:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
@media (min-width: 992px) {
  .custom-navbar .custom-navbar-nav li {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.custom-navbar .custom-navbar-nav li a {
  font-weight: 700;
  /* color: #ffffff !important; */
  color: #394c6c;
  font-size: 16px;
  /* opacity: 0.5; */
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  position: relative;
}

/* .custom-navbar .custom-navbar-nav div a::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  background: #f9bf29;
  height: 5px;
  opacity: 1;
  visibility: visible;
  width: 0;
  -webkit-transition: 0.15s all ease-out;
  -o-transition: 0.15s all ease-out;
  transition: 0.15s all ease-out;
} */

.custom-navbar .navbar-brand {
  font-size: 32px;
  font-weight: 600;
}

.custom-navbar .navbar-brand > span {
  opacity: 0.4;
}

.custom-navbar .navbar-toggler {
  border-color: transparent;
}

.custom-navbar .navbar-toggler:active,
.custom-navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: none;
}

.custom-navbar .custom-navbar-nav li {
  margin-left: 15px;
  margin-right: 15px;
}

.custom-navbar .custom-navbar-nav a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  /* background: #f9bf29;  */
  height: 5px;
  opacity: 1;
  visibility: visible;
  width: 0;
  transition: 0.15s all ease-out;
}
.f-icon {
  font-size: 20px;
  font-weight: 700;
  color: #394c6c;
}

.custom-navbar .custom-navbar-nav a:hover {
  opacity: 1;
}

.custom-navbar .custom-navbar-nav a:hover:before {
  width: calc(100% - 16px);
}

.custom-navbar .custom-navbar-nav li.active a {
  opacity: 1;
}

.custom-navbar .custom-navbar-nav li.active a:before {
  width: calc(100% - 16px);
}

.custom-navbar .custom-navbar-cta {
  margin-left: 0 !important;
  flex-direction: row;
}

@media (min-width: 768px) {
  /* Assuming $md is 768px */
  .custom-navbar .custom-navbar-cta {
    margin-left: 40px !important;
  }
}

.custom-navbar .custom-navbar-cta li {
  margin-left: 0;
  margin-right: 0;
}

.custom-navbar .custom-navbar-cta li:first-child {
  margin-right: 20px;
}

.custom-navbar .custom-navbar-cta {
  margin-left: 0 !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
@media (min-width: 768px) {
  .custom-navbar .custom-navbar-cta {
    margin-left: 40px !important;
  }
}
.custom-navbar .custom-navbar-cta li {
  margin-left: 0px;
  margin-right: 0px;
}
.custom-navbar .custom-navbar-cta li:first-child {
  margin-right: 20px;
}

.hero {
  background: #d8cbba;
  padding: calc(4rem - 30px) 0 0rem 0;
}
@media (min-width: 768px) {
  .hero {
    padding: calc(4rem - 30px) 0 4rem 0;
  }
}
@media (min-width: 992px) {
  .hero {
    padding: calc(8rem - 30px) 0 8rem 0;
  }
}
.hero .intro-excerpt {
  position: relative;
  z-index: 4;
}
@media (min-width: 992px) {
  .hero .intro-excerpt {
    max-width: 450px;
  }
}
.hero h1 {
  font-weight: 700;
  color: #394c6c;
  margin-bottom: 30px;
}
@media (min-width: 1400px) {
  .hero h1 {
    font-size: 54px;
  }
}
.hero p {
  color: #394c6c;
  font-weight: 700;
  margin-bottom: 30px;
}
.hero .hero-img-wrap {
  position: relative;
}
.hero .hero-img-wrap img {
  position: relative;
  top: 0px;
  right: 0px;
  z-index: 2;
  max-width: 780px;
  left: -20px;
}
@media (min-width: 768px) {
  .hero .hero-img-wrap img {
    right: 0px;
    left: -100px;
  }
}
@media (min-width: 992px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    position: absolute;
    right: -50px;
  }
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap img {
    left: 0px;
    top: -80px;
    right: -100px;
  }
}
.hero .hero-img-wrap:after {
  content: "";
  position: absolute;
  width: 255px;
  height: 217px;
  background-image: url("../public/assets/dots-light.svg");
  background-size: contain;
  background-repeat: no-repeat;
  right: -100px;
  top: -0px;
}
@media (min-width: 1200px) {
  .hero .hero-img-wrap:after {
    top: -40px;
  }
}

@media (max-width: 768px) {
  .hero .hero-img-wrap:after {
    display: none;
  }
}

.btn {
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 30px;
  color: #d8cbba;
  background: #394c6c;
  border-color: #394c6c;
}
.btn:hover {
  color: #d8cbba;
  background: #394c6c;
  border-color: #394c6c;
}
.btn:active,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-primary {
  background: #394c6c;
  border-color: #394c6c;
}
.btn.btn-primary:hover {
  background: #394c6c;
  border-color: #394c6c;
}
.btn.btn-secondary {
  color: #d8cbba;
  background: #394c6c;
  border-color: #394c6c;
}

.btn.btn-white-outline {
  background: transparent;
  color: #394c6c;
  border: 2px solid #394c6c !important;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.3);
}
.btn.btn-white-outline:hover {
  border-color: #394c6c;
  color: #394c6c;
}

.section-title {
  color: #2f2f2f;
}

.product-section {
  padding: 7rem 0;
}
.product-section .product-item {
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;
  padding-bottom: 50px;
  cursor: pointer;
}
.product-section .product-item .product-thumbnail {
  margin-bottom: 30px;
  position: relative;
  top: 66px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.product-section .product-item h3 {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}
.product-section .product-item strong {
  font-weight: 800 !important;
  font-size: 18px !important;
  text-decoration: none;
}
.product-section .product-item h3,
.product-section .product-item strong {
  color: #2f2f2f;
  text-decoration: none;
}
.product-section .product-item .icon-cross {
  position: absolute;
  width: 35px;
  height: 35px;
  display: inline-block;
  background: #2f2f2f;
  bottom: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: -17.5px;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.product-section .product-item .icon-cross img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.product-section .product-item:before {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  content: "";
  background: #dce5e4;
  /* background-color: lightcoral; */
  height: 0%;
  z-index: -1;
  border-radius: 10px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.product-section .product-item:hover .product-thumbnail {
  top: 25px;
}
.product-section .product-item:hover .icon-cross {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}
.product-section .product-item:hover:before {
  height: 70%;
}

.why-choose-section {
  padding: 7rem 0;
  /* background-color: #3b5d50; */
}
.why-choose-section .img-wrap {
  position: relative;
}
.why-choose-section .img-wrap:before {
  position: absolute;
  content: "";
  width: 255px;
  height: 217px;
  background-image: url("../public/assets/dots-yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: translate(-40%, -40%);
  -ms-transform: translate(-40%, -40%);
  transform: translate(-40%, -40%);
  z-index: -1;
}
.why-choose-section .img-wrap img {
  border-radius: 20px;
}

.feature {
  margin-bottom: 30px;
}
.feature .icon {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}
.feature .icon:before {
  content: "";
  width: 33px;
  height: 33px;
  position: absolute;
  background: rgba(216, 203, 186, 0.3);
  border-radius: 50%;
  right: -15px;
  bottom: 0;
}

.feature h3 {
  font-size: 14px;
  color: #2f2f2f;
}
.feature p {
  font-size: 14px;
  line-height: 22px;
  color: #6a6a6a;
}

.we-help-section {
  padding: 7rem 0;
}
/* .we-help-section .imgs-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [27];
  grid-template-columns: repeat(27, 1fr);
  position: relative;
} */
.we-help-section .imgs-grid:before {
  position: absolute;
  content: "";
  width: 255px;
  height: 217px;
  background-image: url("../public/assets/dots-green.svg");
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform: translate(-40%, -40%);
  -ms-transform: translate(-40%, -40%);
  transform: translate(-40%, -40%);
  z-index: -1;
}
.we-help-section .imgs-grid .grid {
  position: relative;
}
.we-help-section .imgs-grid .grid img {
  border-radius: 20px;
  max-width: 100%;
}
.we-help-section .imgs-grid .grid.grid-1 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 18;
  grid-column: 1 / span 18;
  -ms-grid-row: 1;
  -ms-grid-row-span: 27;
  grid-row: 1 / span 27;
}
.we-help-section .imgs-grid .grid.grid-2 {
  -ms-grid-column: 19;
  -ms-grid-column-span: 27;
  grid-column: 19 / span 27;
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  grid-row: 1 / span 5;
  padding-left: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 578px) {
  .we-help-section .imgs-grid .grid.grid-3 {
    display: none;
  }
}

.we-help-section .imgs-grid .grid.grid-3 {
  -ms-grid-column: 14;
  -ms-grid-column-span: 16;
  grid-column: 14 / span 16;
  -ms-grid-row: 6;
  -ms-grid-row-span: 27;
  grid-row: 6 / span 27;
  padding-top: 20px;
}

.downs {
  display: flex;
  justify-content: center;
  align-items: end;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: end;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-5wsri9).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  background-color: #d8cbba !important;
  color: #394c6c !important;
  margin-top: 29px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content,
:where(.css-dev-only-do-not-override-5wsri9).ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content {
  color: #394c6c !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.custom-list {
  width: 100%;
}
.custom-list li {
  display: inline-block;
  width: calc(50% - 20px);
  margin-bottom: 12px;
  line-height: 1.5;
  margin-left: 10px;
  position: relative;
  padding-left: 20px;
}
.custom-list li:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #3b5d50;
  position: absolute;
  left: 0;
  top: 8px;
}

.footer-g {
  display: flex;
  padding-top: 60px;
  align-items: center;
  flex-direction: column;
}
.icon-img {
  width: 40px;
}

.popular-product {
  padding: 0 0 7rem 0;
}
.popular-product .product-item-sm h3 {
  font-size: 14px;
  font-weight: 700;
  color: #2f2f2f;
}
.popular-product .product-item-sm a {
  text-decoration: none;
  color: #2f2f2f;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.popular-product .product-item-sm a:hover {
  color: rgba(47, 47, 47, 0.5);
}
.popular-product .product-item-sm p {
  line-height: 1.4;
  margin-bottom: 10px;
  font-size: 14px;
}
.popular-product .product-item-sm .thumbnail {
  margin-right: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  position: relative;
}
.popular-product .product-item-sm .thumbnail:before {
  content: "";
  position: absolute;
  border-radius: 20px;
  background: #dce5e4;
  /* background-color: lightcoral; */
  width: 98px;
  height: 98px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

.testimonial-section {
  padding: 3rem 0 7rem 0;
}

.testimonial-slider-wrap {
  position: relative;
}
.testimonial-slider-wrap .tns-inner {
  padding-top: 30px;
}
.testimonial-slider-wrap .item .testimonial-block blockquote {
  font-size: 16px;
}
@media (min-width: 768px) {
  .testimonial-slider-wrap .item .testimonial-block blockquote {
    line-height: 32px;
    font-size: 18px;
  }
}
.testimonial-slider-wrap .item .testimonial-block .author-info .author-pic {
  margin-bottom: 20px;
}
.testimonial-slider-wrap .item .testimonial-block .author-info .author-pic img {
  max-width: 80px;
  border-radius: 50%;
}
.testimonial-slider-wrap .item .testimonial-block .author-info h3 {
  font-size: 14px;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 0;
}
.testimonial-slider-wrap #testimonial-nav {
  position: absolute;
  top: 50%;
  z-index: 99;
  width: 100%;
  display: none;
}
@media (min-width: 768px) {
  .testimonial-slider-wrap #testimonial-nav {
    display: block;
  }
}
.testimonial-slider-wrap #testimonial-nav > div {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 7px; */
  /* border:2px solid red; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #d8cbba;
  color: #394c6c;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.testimonial-slider-wrap #testimonial-nav > div:hover {
  background: #394c6c;
  color: #d8cbba;
}

.icon {
  font-size: 20px;
  font-weight: 800;
}

.testimonial-slider-wrap #testimonial-nav .prev {
  left: -10px;
}
.testimonial-slider-wrap #testimonial-nav .next {
  right: 0;
}
.testimonial-slider-wrap .tns-nav {
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.testimonial-slider-wrap .tns-nav button {
  background: none;
  border: none;
  display: inline-block;
  position: relative;
  width: 0 !important;
  height: 7px !important;
  margin: 2px;
}
.testimonial-slider-wrap .tns-nav button:active,
.testimonial-slider-wrap .tns-nav button:focus,
.testimonial-slider-wrap .tns-nav button:hover {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
}
.testimonial-slider-wrap .tns-nav button:before {
  display: block;
  width: 7px;
  height: 7px;
  left: 0;
  top: 0;
  position: absolute;
  content: "";
  border-radius: 50%;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  background-color: #d6d6d6;
}
.testimonial-slider-wrap .tns-nav button:hover:before,
.testimonial-slider-wrap .tns-nav button.tns-nav-active:before {
  background-color: #3b5d50;
}

.before-footer-section {
  padding: 7rem 0 12rem 0 !important;
}

.blog-section {
  padding: 7rem 0 12rem 0;
}
.blog-section .post-entry a {
  text-decoration: none;
}
.blog-section .post-entry .post-thumbnail {
  display: block;
  margin-bottom: 20px;
}
.blog-section .post-entry .post-thumbnail img {
  border-radius: 20px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.blog-section .post-entry .post-content-entry {
  padding-left: 15px;
  padding-right: 15px;
}
.blog-section .post-entry .post-content-entry h3 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
  margin-bottom: 7px;
}
.blog-section .post-entry .post-content-entry .meta {
  font-size: 14px;
}
.blog-section .post-entry .post-content-entry .meta a {
  font-weight: 600;
}
.blog-section .post-entry:hover .post-thumbnail img,
.blog-section .post-entry:focus .post-thumbnail img {
  opacity: 0.7;
}

.footer-section {
  padding: 40px 0;
  background: #d8cbba;
}
.footer-section .relative {
  position: relative;
}
.footer-section a {
  text-decoration: none;
  color: #2f2f2f;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.footer-section a:hover {
  color: rgba(47, 47, 47, 0.5);
}
.footer-section .subscription-form {
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}
@media (min-width: 992px) {
  .footer-section .subscription-form {
    margin-top: 0px;
    margin-bottom: 80px;
  }
}
.footer-section .subscription-form h3 {
  font-size: 18px;
  font-weight: 500;
  color: #3b5d50;
}
.footer-section .subscription-form .form-control {
  height: 50px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
}
.footer-section .subscription-form .form-control:active,
.footer-section .subscription-form .form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #3b5d50;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.footer-section .subscription-form .form-control::-webkit-input-placeholder {
  font-size: 14px;
}
.footer-section .subscription-form .form-control::-moz-placeholder {
  font-size: 14px;
}
.footer-section .subscription-form .form-control:-ms-input-placeholder {
  font-size: 14px;
}
.footer-section .subscription-form .form-control:-moz-placeholder {
  font-size: 14px;
}
.footer-section .subscription-form .btn {
  border-radius: 10px !important;
}
.footer-section .sofa-img {
  position: absolute;
  top: -200px;
  z-index: 1;
  right: 0;
}
.footer-section .sofa-img img {
  max-width: 380px;
}
.footer-section .links-wrap {
  margin-top: 0px;
}
@media (min-width: 992px) {
  .footer-section .links-wrap {
    margin-top: 54px;
  }
}
.footer-section .links-wrap ul li {
  margin-bottom: 10px;
}
.footer-section .footer-logo-wrap .footer-logo {
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  color: #3b5d50;
}
.footer-section .custom-social {
  margin: 2px;
  /* display: inline-block; */
  display: flex;
  justify-content: start;
}
.footer-section .custom-social div a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: flex;
  justify-content: center;
  /* align-items: ; */
  padding-top: 3px;
  background-color: #d8cbba;
  border-radius: 50%;
}

.footer-section .border-top {
  border-color: #dce5e4;
}
.footer-section .border-top.copyright {
  font-size: 14px !important;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.untree_co-section {
  padding: 7rem 0;
}

.form-control {
  height: 50px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
}
.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #3b5d50;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.form-control::-webkit-input-placeholder {
  font-size: 14px;
}
.form-control::-moz-placeholder {
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  font-size: 14px;
}
.form-control:-moz-placeholder {
  font-size: 14px;
}

.service {
  line-height: 1.5;
}
.service .service-icon {
  border-radius: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #394c6c;
  margin-right: 7px;
  color: #d8cbba;
}
.service-contents {
  font-size: 13px;
}

.txt-forms {
  padding-top: 15px;
}

textarea {
  height: auto !important;
}

.site-blocks-table {
  overflow: auto;
}
.site-blocks-table .product-thumbnail {
  width: 200px;
}
.site-blocks-table .btn {
  padding: 2px 10px;
}
.site-blocks-table thead th {
  padding: 30px;
  text-align: center;
  border-width: 0px !important;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}
.site-blocks-table td {
  padding: 20px;
  text-align: center;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.8);
}
.site-blocks-table tbody tr:first-child td {
  border-top: 1px solid #3b5d50 !important;
}
.site-blocks-table .btn {
  background: none !important;
  color: #000000;
  border: none;
  height: auto !important;
}

.site-block-order-table th {
  border-top: none !important;
  border-bottom-width: 1px !important;
}

.site-block-order-table td,
.site-block-order-table th {
  color: #000000;
}

.couponcode-wrap input {
  border-radius: 10px !important;
}

.text-primary {
  color: #3b5d50 !important;
}

.thankyou-icon {
  position: relative;
  color: #3b5d50;
}
.thankyou-icon:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(59, 93, 80, 0.2);
}

/* karthika */

.we-help-section {
  padding: 5rem 0; /* Reduced padding for smaller screens */

  .imgs-grid {
    display: grid;
    grid-template-columns: repeat(27, 1fr);
    position: relative;

    .grid {
      position: relative;

      img {
        border-radius: 20px;
        max-width: 100%;
      }

      &.grid-1 {
        grid-column: 1 / span 18;
        grid-row: 1 / span 27;
      }

      &.grid-2 {
        grid-column: 19 / span 27;
        grid-row: 1 / span 5;
        padding-left: 20px;
      }

      &.grid-3 {
        grid-column: 14 / span 16;
        grid-row: 6 / span 27;
        padding-top: 20px;
      }
    }
  }

  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .imgs-grid {
      grid-template-columns: repeat(
        12,
        1fr
      ); /* Adjust grid for smaller screens */
    }

    .grid-1 {
      grid-column: 1 / span 10;
    }

    .grid-2 {
      grid-column: 11 / span 12;
    }

    .grid-3 {
      grid-column: 8 / span 10;
    }
  }

  @media (max-width: 768px) {
    .imgs-grid {
      grid-template-columns: repeat(
        6,
        1fr
      ); /* Further adjustments for tablets */
    }

    .grid-1 {
      grid-column: 1 / span 6;
      grid-row: 1 / span 15; /* Adjust row span for better layout */
    }

    .grid-2 {
      grid-column: 1 / span 6;
      grid-row: auto; /* Auto row placement */
      padding-left: 0;
    }

    .grid-3 {
      grid-column: 1 / span 6;
      grid-row: auto;
      padding-top: 10px;
    }
  }

  @media (max-width: 480px) {
    .imgs-grid {
      grid-template-columns: repeat(4, 1fr); /* Mobile adjustments */
    }

    .grid-1,
    .grid-2,
    .grid-3 {
      grid-column: 1 / span 4;
      grid-row: auto;
    }
  }
}

/* Testimonial Section Styles */
.testimonial-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #394c6c;
}

/* Slider Container */
.testimonial-slider-wrap {
  position: relative;
  padding: 20px;
}

/* Navigation buttons for slider */
#testimonial-nav {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

/* Testimonial Content */
.testimonial-slider .item {
  padding: 10px;
}

.testimonial-block {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.testimonial-block blockquote {
  font-size: 1.2rem;
  font-style: italic;
  color: #666;
  margin-bottom: 30px;
}

.author-info {
  margin-top: 20px;
}

.author-pic img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.font-weight-bold {
  font-weight: 700;
}

.footer-section {
  padding: 20px 0;
  background: #d8cbba; /* Replace $white */
}

.footer-section .relative {
  position: relative;
}

.footer-section a {
  text-decoration: none;
  color: #394c6c !important; /* Replace $dark */
  transition: 0.3s all ease;
}

.footer-section a:hover {
  color: lightgray; /* Replace $dark with #333333 */
}

.product-divs {
  width: 20%;
  background-color: #d8cbba;
  height: 200px;
  position: absolute;
  top: 20%;
  border-radius: 10px;
}
.product-divs > p {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: end;
}

@media screen and (min-width: 579px) and (max-width: 880px) {
  .product-divs {
    top: 15%;
    width: 28%;
  }
}

:where(.css-5wsri9).ant-card {
  background-color: transparent !important;
}

:where(.css-5wsri9).ant-card .ant-card-body {
  padding: 16px !important;
  background-color: transparent !important;
}
:where(.css-5wsri9).ant-card .ant-card-cover > * {
  border-radius: 10px !important;
}

.footer-section .subscription-form {
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  margin-top: 100px;
}

@media (min-width: 992px) {
  /* Replace $lg */
  .footer-section .subscription-form {
    margin-top: 0px;
    margin-bottom: 80px;
  }
}

.footer-section .subscription-form h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000; /* Replace $primary */
}

.footer-section .subscription-form .form-control {
  height: 50px;
  border-radius: 10px;
  font-family: Arial, sans-serif; /* Replace $font-family */
}

.footer-section .subscription-form .form-control:active,
.footer-section .subscription-form .form-control:focus {
  outline: none;
  box-shadow: none; /* Replace $primary */
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); /* Replace $black */
}

.footer-section .subscription-form .form-control::-webkit-input-placeholder,
.footer-section .subscription-form .form-control::-moz-placeholder,
.footer-section .subscription-form .form-control:-ms-input-placeholder,
.footer-section .subscription-form .form-control:-moz-placeholder {
  font-size: 14px;
}

.footer-section .subscription-form .btn {
  border-radius: 10px !important;
}

.footer-section .sofa-img {
  position: absolute;
  top: -200px;
  z-index: 1;
  right: 0;
}

.footer-section .sofa-img img {
  max-width: 380px;
}

.footer-section .links-wrap {
  margin-top: 0px;
}

@media (min-width: 992px) {
  /* Replace $lg */
  .footer-section .links-wrap {
    margin-top: 54px;
  }
}

.footer-section .links-wrap ul li {
  margin-bottom: 10px;
}

.footer-section .footer-logo-wrap .footer-logo {
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  color: #394c6c; /* Replace $primary */
}

.footer-section .custom-social li {
  margin: 2px;
  display: inline-block;
}

.footer-section .custom-social li a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  background: #f8f9fa; /* Replace $light */
  color: #000; /* Replace $primary */
  border-radius: 50%;
  transition: background-color 0.4s ease, color 0.4s ease;
}

.footer-section .border-top {
  border-color: #f8f9fa; /* Replace $light */
}

.footer-section .border-top.copyright {
  font-size: 14px !important;
}

/* styles.css */
.product-item {
  position: relative; /* To position the pseudo-element */
  overflow: hidden; /* To ensure the background stays within the item */
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: all 5s linear;
}

.product-thumbnail {
  width: 100%;
  height: 200px;
}

@media screen and (min-width: 1030px) and (max-width: 1440px) {
  .product-item::after {
    border-radius: 10px;
  }
}

.product-thumbnail {
  position: relative;
  display: flex;
  z-index: 1; /* Make sure the image stays above the pseudo-element */
}
.product-title {
  position: relative;
  text-decoration: none !important;
  z-index: 3;
  font-size: 16px;
  font-weight: 600;
}

.product-price {
  position: relative;
  text-decoration: none !important;
  font-size: 18px;
  padding-bottom: 20px;
  z-index: 3;
}

.product-thumbnail {
  position: relative; /* Allow for positioning */
  transition: transform 0.5s ease; /* Transition for the transform property */
}

.product-item:hover .product-thumbnail {
  transform: translateY(10px); /* Move the image down by 20 pixels */
}

.product-gap {
  padding-top: 130px;
  padding-bottom: 30px;
}

.top-g {
  padding-top: 84px;
}
.social {
  font-size: 20px;
  color: #394c6c;
}

.bg-img {
  background-image: url("../public/assets/bg-h.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.bg-2 {
  width: 100%;
  /* height: 100vh; */
}

.section-bg {
  background-color: #d8cbba;
}

.txt-1-a {
  color: #394c6c;
  text-align: center;
}
.achive-bg {
  color: #394c6c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loc-txt {
  font-size: 16px;
  font-weight: 600;
  color: #394c6c;
  padding-left: 20px;
}
.img-contain {
  width: 70px;
}

.arr-btns-1 {
  position: absolute;
  left: 7%;
  width: 40px;
  height: 40px;
  color: #394c6c !important;
  border: none !important;
  background-color: #d8cbba !important;
  font-size: 2rem;
  padding-top: 10px;
}

.arr-btns-2 {
  position: absolute;
  right: 9%;
  width: 40px;
  height: 40px;
  color: #394c6c !important;
  border: none !important;
  background-color: #d8cbba !important;
  font-size: 2rem;
  padding-top: 10px;
}

.xx {
  position: absolute;
  top: 20px;
  right: 50px;
  border: none !important;
  color: #394c6c !important;
  background-color: #d8cbba !important;
  font-size: 1.5rem;
  padding-top: 10px;
}
.c-txts {
  font-size: 14px;
  font-weight: 600;
  padding-left: 0px;
}

.img-center {
  width: 400px;
}
.img-center > img {
  width: auto;
  height: auto;
}

.fullscreen-image-viewer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 30px;
}

@media screen and (min-width: 320px) and (max-width: 578px) {
  .img-center {
    width: 100%;
  }

  .fullscreen-image-viewer {
    padding: 10px !important;
    width: 100% !important;
  }

  .arr-btns-2 {
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
  }
  .arr-btns-1 {
    background-color: #000 !important;
    opacity: 0.8;
    color: #fff !important;
  }
  .map-frames,
  .maps {
    height: 250px !important;
  }
  .arr-btns-2 {
    width: 20px;
    right: 7%;
    height: 30px;
    font-size: 18px;
    padding-top: 8px;
  }
  .arr-btns-1 {
    width: 20px;
    height: 30px;
    font-size: 18px;
    padding-top: 8px;
  }
  .xx {
    width: 20px;
    right: 10px;
  }
}

@media screen and (min-width: 578px) and (max-width: 880px) {
  .img-center {
    max-width: 75%;
  }
  .arr-btns-2 {
    width: 20px;
    right: 7%;
    height: 30px;
    font-size: 18px;
    padding-top: 8px;
  }
  .map-frames,
  .maps {
    height: 350px !important;
  }
  .c-rows {
    gap: 20px;
  }
  .arr-btns-1 {
    width: 20px;
    height: 30px;
    font-size: 18px;
    padding-top: 8px;
  }
  .xx {
    width: 20px;
    right: 10px;
  }
}

.a-txt-1 {
  font-size: 18px;
  font-weight: 600;
}

.hotdeal-image {
  width: 97px;
  transition: all 1s ease;
}
.table-div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.hotdeal-div {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.less-div,
.greater-div {
  width: 30px;
  border-radius: 50%;
  height: 30px;
  margin-top: 33px;
}
.less,
.greater {
  border: none;
  background-color: #d8cbba;
  color: #394c6c;
  width: 30px;
  border-radius: 50%;
  padding-top: 3px;
  cursor: pointer;
  height: 30px;
}
.less:active,
.greater:active {
  background-color: #394c6c;
  color: #fff;
}
.icon-arrow {
  font-size: 20px;
  font-weight: 800;
}

.combine-div {
  width: 106px;
  height: 102px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  transition: all 1s ease;
  cursor: pointer;
}

.combine-div:hover .hotdeal-image {
  transform: translateY(-35%);
}
.combine-div:hover {
  background-color: #394c6c99;
}

.img-para {
  font-size: 14px;
  font-weight: 600;
  color: #424242;
  text-align: center;
  margin-top: 5px;
}

.card-img {
  /* width: 404px; */
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}


.product-txt {
  color: #394c6c;
  padding-top: 30px;
  font-size: 35px;
  font-weight: 800;
}
.nav-txt {
  padding-right: 1rem;
  display: flex;
  align-items: center;
}
.dropdowns-c {
  display: flex;
  gap: 30px;
}
.nav-com {
  display: flex !important;
  gap: 25px;
}
.nav-s {
  font-weight: 600;
}

@media screen and (min-width: 320px) and (max-width: 578px) {
  .product-txt {
    padding-top: 10px;
  }
  .dropdowns-c {
    gap: 10px;
  }
  .nav-com {
    padding-left: 16px !important;
  }
  .products-row {
    padding-top: 10px !important;
  }
  .logo {
    padding-left: 16px !important;
  }
  .gaps {
    padding: 16px;
  }

}

.small-divs-container {
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.arr-s {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #394c6c !important;
  color: #d8cbba !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  font-size: 18px;
}
.images-small {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.logo-f {
  width: 180px;
  height: 60px;
}

.btn-m {
  background-color: #394c6c !important;
  color: #d8cbba !important;
  border: none !important;
  padding: 0px 50px;
}
.modal-img {
  width: 80px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-c {
  font-size: 16px;
  color: #000;
  text-align: center;
}
/* @media screen and (min-width: 1030px) and (max-width: 1440px) {
  .card-img {
    width: 415px !important;
  }
} */

.offer-img-product {
  background-color: #8894a733;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 1s ease;
}

.offer-img-product:hover {
  box-shadow: 3px 3px 5px lightgray;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-card .ant-card-body {
  padding: 0px !important;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-card {
  background: none !important;
}

.offer-btns {
  background-color: #394c6c !important;
  color: #fff !important;
  border: 1px solid #394c6c !important;
  font-size: 10px;
  font-weight: 700;
}

.card-img-div {
  width: 271px;
  height: 267px;
}

.card-img-div > img {
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  height: 265px;
}

:where(.css-dev-only-do-not-override-5wsri9).ant-card .ant-card-cover > * {
  border-radius: 10px;
}

.card-txt-h {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.logo {
  width: 150px;
  cursor: pointer;
}
.star {
  color: #ffba35;
  font-size: 14px;
  margin-left: 3px;
}
.rating {
  font-size: 14px;
}

.btn-change-div {
  min-width: 310px;
}

.btn-change {
  background-color: #394c6c !important;
  color: #fff !important;
  border: 1px solid #394c6c !important;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
}

.btn-change-1 {
  background-color: #394c6c !important;
  color: #fff !important;
  border: 1px solid #394c6c !important;
  width: 310px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
}

.box-small {
  width: 86px;
  background-color: #8894a733;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-box-s {
  width: 65px;
}

.card-description {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8894a733 !important;
}

.enquiery-img {
  width: 330px;
}

.e-txt {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-bottom: 5px;
}

.e-txt-1 {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 8px;
}

.e-txt-h {
  font-size: 20px;
  color: #333333;
  font-weight: 700;
}
.e-rate {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.rating-e {
  font-size: 13px;
}
.e-icon-div {
  margin-top: 2px;
}
.e-icons {
  font-size: 14px;
  cursor: pointer;
  color: #333333;
}
.img-about {
  width: 550px;
  height: 250px;
  border-radius: 10px;
}
.img-about > img {
  border-radius: 10px;
  width: 100%;
  height: 250px;
}

.about-rows {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 578px) and (max-width: 768px) {
  .btn-change {
    width: 300px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 578px) {
  .card-description {
    width: 100%;
    height: auto;
  }
  .img-about {
    width: 100%;
  }
  .enquiery-img {
    width: 100%;
  }
  .hero .intro-excerpt {
    padding-top: 70px;
  }
  .e-txt {
    font-size: 14px;
    line-height: 23px;
  }
  .about-bg {
    background-size: cover;
    height: 300px;
  }
  .top-g {
    padding-top: 0px !important;
  }
  .c-rows {
    gap: 20px;
  }
  .img-about {
    height: 250px;
  }
  .img-contain {
    width: 40px;
  }
  .a-txt {
    font-size: 20px;
    margin-top: 10px;
  }
  .a-txt-1 {
    font-size: 15px;
  }
  .rows-combine {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
  }
  .row-change {
    flex-direction: column-reverse;
  }
  .img-about > img {
    height: 250px;
  }
  .btn-change-1-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  :where(.css-dev-only-do-not-override-5wsri9).ant-dropdown .ant-dropdown-menu,
  :where(.css-dev-only-do-not-override-5wsri9).ant-dropdown-menu-submenu
    .ant-dropdown-menu {
    margin-top: 0px !important;
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .btn-change-div {
    width: 230px !important;
  }
  .btn-change {
    width: 100%;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .btn-change {
    padding: 17px 150px;
  }
  .btn-change-1 {
    padding: 17px 170px;
  }
}

.rows-combine {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
}

.products-row {
  padding-top: 50px;
}
.maps {
  width: 100%;
  height: 500px;
}
.map-frames {
  width: 100%;
  height: 500px;
}

@media screen and (min-width: 579px) and (max-width: 880px) {
  .rows-combine {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
  }
  .testimonial-block {
    padding: 0px !important;
  }
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 4s ease-in;
}

.product-item {
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
}

.product-thumbnail {
  width: 100%; /* Adjust as necessary */
  max-width: 100%; /* Prevent overflow */
}

.slider-button {
  background-color: #394c6c;
  border: none; /* Button background */ /* Button border */
  font-size: 24px;
  color: #d8cbba;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
  border-radius: 5px; /* Rounded corners */
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: background-color 0.3s; /* Transition effect */
}

.slider-button:hover {
  background-color: #d8cbba;
  border: 1px solid #394c6c;
  color: #394c6c; /* Change color on hover */
}

.slider-button:first-child {
  left: 10px; /* Adjust as necessary */
}

.slider-button:last-child {
  right: 10px; /* Adjust as necessary */
}

/* Hide the slider on larger screens */
@media (min-width: 768px) {
  .slider-container {
    display: none; /* Hide the slider on larger screens */
  }
}

/* Show static images on larger screens */
.d-none.d-md-block {
  display: block; /* Show static images on medium and larger screens */
}
.indicator-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px; /* Adjust size as needed */
  height: 10px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: lightgray; /* Default color */
  margin: 0 5px; /* Spacing between indicators */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.indicator.active {
  background-color: #394c6c; /* Color when active */
}

.bg-body-tertiary {
  background-color: #d8cbba !important;
}
.nav-link {
  color: #394c6c !important;
}
.what {
  align-items: center !important;
  padding-right: 1rem;
}
.dropdown-menu {
  background-color: #fff;
  border: none;
  padding-bottom: 30px;
  margin-top: 18px !important;
}
.drop {
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  padding-bottom: 0px !important;
  color: #394c6c !important;
  background-color: #fff !important;
}

.navbar-toggler {
  border: none !important;
  color: transparent !important;
  transition: 0.2s ease-in-out;
}
.send-btn{
  background-color: #394c6c !important;
  color: #fff !important;
  border-color: #394c6c !important;
  width: 100px !important;
}
.model-dfv h6,.model-dfv label{
  color: #201f1f;
}
